import { Table } from "react-bootstrap";

const Anamnesis = ({ anamnesis }) => {
    return (
        <Table striped>
            <thead className="bg-secondary">
                <tr>
                    <th>Key</th>
                    <th className="text-end">Value</th>
                </tr>
            </thead>
            <tbody>
                {/* eslint-disable-next-line */}
                {anamnesis.map(property => {
                    if (
                        !property.labels.name.includes("image_urls") &&
                        (property.data || typeof property.data === "boolean")
                    ) {
                        return (
                            <tr key={property.labels.name} className="my-2">
                                <td>
                                    <span className="fw-bold">{`${property.labels.label.toUpperCase()} `}</span>
                                </td>
                                <td className="text-end">
                                    {Array.isArray(property.data) ? (
                                        <ul>
                                            {property.data.map(option => {
                                                return (
                                                    <li
                                                        key={option}
                                                        className="text-end"
                                                    >
                                                        {(
                                                            option[0].toUpperCase() +
                                                            option.slice(
                                                                1,
                                                                option.length
                                                            )
                                                        ).replace("_", " ")}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ) : !property.data ? (
                                        "No"
                                    ) : property.data === true ? (
                                        "Yes"
                                    ) : (
                                        (() => {
                                            if (
                                                /^([^0-9]*)$/.test(
                                                    property.data
                                                )
                                            ) {
                                                const capitalized =
                                                    property.data[0].toUpperCase() +
                                                    property.data.slice(
                                                        1,
                                                        property.data.length
                                                    );
                                                return capitalized.replaceAll(
                                                    "_",
                                                    " "
                                                );
                                            } else {
                                                return property.data;
                                            }
                                        })()
                                    )}
                                </td>
                            </tr>
                        );
                    }
                })}
            </tbody>
        </Table>
    );
};

export default Anamnesis;
