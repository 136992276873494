import { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const Checkbox = ({ mission }) => {
    const { taskData, setTaskData } = useContext(WorkspaceContext);

    const taskName = mission.name;
    const taskOptions = mission.values;

    const [selected, setSelected] = useState([]);

    useEffect(
        () => {
            if (selected.length) {
                setTaskData({ ...taskData, [taskName]: selected });
            }
            if (!selected.length && taskData[taskName]) {
                setTaskData({ ...taskData, [taskName]: [] });
            }
        },
        // eslint-disable-next-line
        [selected]
    );

    const handleChange = e => {
        setSelected(prevState => {
            let updatedState;
            if (prevState.includes(e.target.value)) {
                updatedState = prevState.filter(
                    option => option !== e.target.value
                );
            } else {
                updatedState = [...prevState, e.target.value];
            }
            return updatedState;
        });
    };

    return (
        <div className="py-4 fieldset fieldset-checkbox">
            <h6>{mission.description}</h6>
            <div>
                {taskOptions.map(option => {
                    return (
                        <Form.Check
                            inline
                            key={taskName + "-" + option.value}
                            className="me-4"
                        >
                            <Form.Check.Input
                                name={taskName}
                                type="checkbox"
                                onChange={handleChange}
                                value={option.value}
                                id={taskName + "-" + option.value}
                            />
                            <Form.Check.Label
                                htmlFor={taskName + "-" + option.value}
                            >
                                {option.label}
                            </Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </div>
        </div>
    );
};

export default Checkbox;
