import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import axios from "../../utilities/axiosInstance";
import { Alert, Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BoxArrowInRight, ExclamationCircle } from "react-bootstrap-icons";

const LoginBody = ({ isLoggingOut, setIsLoggingOut }) => {
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    /* eslint-disable */

    const [signedUp, setSignedUp] = useState(false);

    const [showLoginSpinner, setShowLoginSpinner] = useState(false);

    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loginAlertMsg, setLoginAlertMsg] = useState("");

    const [redirectOnLogin, setRedirectOnLogin] = useState(
        "/task-type-selection"
    );

    /* eslint-enable */

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const justSignedUp = parseInt(params.get("signedUp"));
        if (justSignedUp) {
            setSignedUp(true);
        }

        const redirectOnLogin = params.get("redirectOnLogin");
        if (redirectOnLogin) setRedirectOnLogin(redirectOnLogin);
    }, [location.search]);

    const { setCurrentUser } = useContext(AuthContext);

    useEffect(() => {
        const handleLogout = () => {
            localStorage.removeItem("authToken");
            setCurrentUser({ id: null, firstName: "", role: null });
        };
        if (isLoggingOut) handleLogout();
    }, [setCurrentUser, isLoggingOut]);

    const handleLoginDataChange = e => {
        setLoginData({
            ...loginData,
            [e.target.id]: e.target.value,
        });
    };

    const navigate = useNavigate();

    const handleLogin = async e => {
        e.preventDefault();
        setShowLoginAlert(false);
        setShowLoginSpinner(true);
        try {
            const res = await axios.post("/auth/login", loginData);
            if (res.data.ok) {
                localStorage.setItem("authToken", res.data.authToken);
                const { role } = res.data.user;
                if (role === 3 && redirectOnLogin === "/task-type-selection") {
                    navigate("/dashboard");
                } else {
                    navigate(redirectOnLogin);
                }
            }
        } catch (err) {
            setLoginAlertMsg(err.response.data.message);
            setShowLoginAlert(true);
        } finally {
            setShowLoginSpinner(false);
        }
    };

    return (
        <main className="d-flex flex-column justify-content-center align-items-center">
            <Alert
                variant="success"
                dismissible
                show={isLoggingOut}
                onClose={() => setIsLoggingOut(false)}
                className="d-flex justify-content-around align-items-center"
            >
                <ExclamationCircle className="me-2" />
                You have sucessfully logged out.
            </Alert>
            <Alert
                variant="success"
                dismissible
                show={signedUp}
                onClose={() => setSignedUp(false)}
                className="d-flex justify-content-around align-items-center"
            >
                <ExclamationCircle className="me-2" />
                Sign-up successful. Please log in.
            </Alert>
            <h2 className="mb-5">Welcome to the MyDerma Annotation Tool</h2>
            <Form id="login-form" onSubmit={handleLogin}>
                <div className="mb-3">
                    First-time user?
                    <Link to="/signup" className="ms-1">
                        Sign Up
                    </Link>
                </div>
                <FloatingLabel label="Email or Username" className="mb-2">
                    <Form.Control
                        required
                        id="email"
                        value={loginData.email}
                        onChange={handleLoginDataChange}
                        placeholder="Email or Username"
                    />
                </FloatingLabel>
                <FloatingLabel label="Password" className="mb-2">
                    <Form.Control
                        required
                        type="password"
                        id="password"
                        value={loginData.password}
                        onChange={handleLoginDataChange}
                        placeholder="Password"
                        className="mb-3"
                    />
                </FloatingLabel>
                <div
                    className={`d-flex justify-content-${
                        showLoginSpinner || showLoginAlert ? "between" : "end"
                    } align-items-center`}
                >
                    <div>
                        {showLoginSpinner && <Spinner variant="primary" />}
                        <Alert
                            show={showLoginAlert}
                            id="login-alert"
                            variant="danger"
                            className="px-2 py-1 me-2 mb-0"
                        >
                            {loginAlertMsg}
                        </Alert>
                    </div>
                    <Button type="submit">
                        <BoxArrowInRight className="me-2" />
                        Log In
                    </Button>
                </div>
            </Form>
        </main>
    );
};

export default LoginBody;
