import { useContext } from "react";
import { WorkspaceContext } from "../../contexts/Workspace";
import Bool from "./fieldsets/Bool";
import Radio from "./fieldsets/Radio";
import Checkbox from "./fieldsets/Checkbox";
import Number from "./fieldsets/Number";
import Locations from "./fieldsets/Locations";
import DifferentialDiagnosis from "./fieldsets/DifferentialDiagnosis";
import SkinType from "./fieldsets/SkinType";
import Validity from "./fieldsets/Validity";

const AnnotationPane = () => {
    const { taskSetup } = useContext(WorkspaceContext);
    return (
        <div id="workspace-annotation-pane" className="p-2">
            <h4>Please complete the annotation task(s) below:</h4>
            {taskSetup.mission.map(mission => {
                switch (mission.type) {
                    case "bool":
                        return <Bool key={mission.id} mission={mission} />;
                    case "radio":
                        return <Radio key={mission.id} mission={mission} />;
                    case "checkbox":
                        return <Checkbox key={mission.id} mission={mission} />;
                    case "number":
                        return <Number key={mission.id} mission={mission} />;
                    case "location":
                        return <Locations key={mission.id} mission={mission} />;
                    case "diff_diagnosis":
                        return (
                            <DifferentialDiagnosis
                                key={mission.id}
                                mission={mission}
                            />
                        );
                    case "skin_type":
                        return <SkinType key={mission.id} mission={mission} />;
                    default:
                        return null;
                }
            })}
            <Validity />
        </div>
    );
};

export default AnnotationPane;
