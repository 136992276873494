import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WorkspaceContext } from "../contexts/Workspace";
import { AuthContext } from "../contexts/Auth";
import { fetchTask } from "../utilities/fetchTask";
import { fetchImages } from "../utilities/fetchImages";
import { validateCheckboxes } from "../utilities/validateCheckboxes";
import { submitTask } from "../utilities/submitTask";
import Header from "../components/global/Header";
import TaskNavbar from "../components/workspace/TaskNavbar";
import TaskDetailsPane from "../components/workspace/TaskDetailsPane";
import AnnotationPane from "../components/workspace/AnnotationPane";
import Footer from "../components/global/Footer";
import { Spinner } from "react-bootstrap";
import "../styles/Workspace.css";

const Workspace = () => {
    document.title = "Annotation Workspace | MyDerma Annotaton Tool";

    const {
        taskSetup,
        setTaskSetup,
        taskData,
        setCaseImages,
        setIsSaving,
        setSaveAlertMessage,
        setShowSaveAlert,
    } = useContext(WorkspaceContext);

    const { currentUser } = useContext(AuthContext);

    const location = useLocation();
    const navigate = useNavigate();

    const queryTaskId = new URLSearchParams(location.search).get("task_id");
    const queryMissionId = new URLSearchParams(location.search).get(
        "mission_id"
    );
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch task by Task ID, if present. If not, fetch by Mission ID
        const getTask = async () => {
            try {
                if (!queryTaskId && !queryMissionId)
                    throw new Error("No task ID or mission ID provided");
                let res;
                if (queryTaskId) {
                    res = await fetchTask("task", queryTaskId);
                } else {
                    res = await fetchTask("mission", queryMissionId);
                }
                setTaskSetup({
                    task: res.task,
                    mission: res.mission,
                });
                navigate(
                    `/workspace?mission_id=${res.task.missionId}&task_id=${res.task.taskId}`
                );
            } catch (err) {
                console.log(err);
                navigate(`/task-type-selection`);
            }
        };
        getTask();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const retrieveImages = async () => {
            if (taskSetup.task.anamnesis) {
                try {
                    const imageObjectsInAnamnesis =
                        await taskSetup.task.anamnesis.filter(property =>
                            property.labels.name.includes("image_urls")
                        );

                    let imageUrls = [];
                    for (const image of imageObjectsInAnamnesis) {
                        if (image.data) {
                            imageUrls = imageUrls.concat(image.data);
                        }
                    }
                    const imageFilenames = imageUrls.map(image =>
                        image.replace(
                            "gs://dermadetect-20180424.appspot.com/000RN/",
                            ""
                        )
                    );
                    const images = await fetchImages(imageFilenames);
                    setCaseImages(images);
                    setIsLoading(false);
                } catch (err) {
                    console.log(err);
                    navigate(`/task-type-selection`);
                }
            }
        };
        retrieveImages();
        // eslint-disable-next-line
    }, [taskSetup.task.anamnesis]);

    const handleTaskSubmit = async e => {
        e.preventDefault();
        setIsSaving(true);
        setShowSaveAlert(false);
        try {
            if (taskSetup.mission.length === 1) {
                const checkboxValidationPassed = validateCheckboxes(
                    taskSetup,
                    taskData
                );
                if (!checkboxValidationPassed)
                    throw new Error(
                        "Empty checkboxes present, at least one must be checked"
                    );
            }
            const metadataAnnotation = JSON.parse(JSON.stringify(taskData));
            delete metadataAnnotation.validity;
            const taskSubmission = {
                taskId: taskSetup.task.taskId,
                relevancy: taskData.validity,
                metadataAnnotation,
                userId: currentUser.id,
            };
            const success = await submitTask(taskSubmission);
            if (!success)
                throw new Error("Error saving task. Please try again.");
            navigate(`/workspace?mission_id=${queryMissionId}`);
            window.location.reload();
        } catch (err) {
            console.log(err);
            if (err.message) {
                setSaveAlertMessage(err.message);
                setShowSaveAlert(true);
            }
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div id="workspace" className="page-layout">
            <Header subtitle="Annotation Workspace"></Header>
            <main>
                {isLoading && (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "calc(100vh - 100px)" }}
                    >
                        <Spinner
                            variant="primary"
                            style={{
                                height: "200px",
                                width: "200px",
                                borderWidth: "10px",
                            }}
                        />
                    </div>
                )}
                {!isLoading && (
                    <form onSubmit={handleTaskSubmit}>
                        <TaskNavbar />
                        <div
                            id="workspace-pane-container"
                            className="d-flex px-3 pt-3"
                        >
                            <TaskDetailsPane />
                            <AnnotationPane />
                        </div>
                    </form>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default Workspace;
