import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TaskReviewContext } from "../contexts/TaskReview";
import { fetchTaskList } from "../utilities/fetchTaskList";
import { fetchRemainingTaskReviewData } from "../utilities/fetchRemainingTaskReviewData";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import TextPane from "../components/task-review/TextPane";
import ImagePane from "../components/task-review/ImagePane";
import MetadataPane from "../components/task-review/MetadataPane";
import { Spinner } from "react-bootstrap";
import "../styles/TaskReview.css";
import TaskReviewNavbar from "../components/task-review/TaskReviewNavbar";

const TaskReview = () => {
    document.title = "Task Review | MyDerma Annotaton Tool";

    const { taskReviewBank, setTaskReviewBank } = useContext(TaskReviewContext);

    const location = useLocation();
    const queryTaskId = new URLSearchParams(location.search).get("task_id");

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!Object.keys(taskReviewBank).length) {
            const populateTaskList = async () => {
                const tasks = await fetchTaskList();
                const filtered = tasks.filter(
                    task => task.status !== "not-started"
                );
                let obj = {};
                for (const task of filtered) {
                    obj[task.task_id] = task;
                }
                setTaskReviewBank(obj);
            };
            try {
                populateTaskList();
            } catch (err) {
                console.log(err);
            }
        }
    }, [taskReviewBank, setTaskReviewBank]);

    useEffect(() => {
        if (
            Object.keys(taskReviewBank).length &&
            !Object.keys(taskReviewBank[queryTaskId]).includes("anamnesis")
        ) {
            // Consolidate above into this one function
            const getRemainingTaskReviewData = async () => {
                setIsLoading(true);
                const data = await fetchRemainingTaskReviewData(
                    queryTaskId,
                    taskReviewBank[queryTaskId]["case_id"],
                    taskReviewBank[queryTaskId]["mission_id"]
                );
                const {
                    anamnesis,
                    images,
                    bitmap,
                    metadata,
                    validity,
                    annotator_id,
                } = data;
                await setTaskReviewBank({
                    ...taskReviewBank,
                    [queryTaskId]: {
                        ...taskReviewBank[queryTaskId],
                        anamnesis,
                        images,
                        bitmap,
                        metadata,
                        validity,
                        annotator_id,
                    },
                });
                setIsLoading(false);
            };

            try {
                getRemainingTaskReviewData();
            } catch (err) {
                console.log(err);
                window.alert("Sorry, task review data could not be retrieved");
            }
        }
    }, [queryTaskId, taskReviewBank, setTaskReviewBank]);

    return (
        <div id="task-review" className="page-layout">
            <Header subtitle="Task Review" />
            <main>
                <TaskReviewNavbar
                    taskReviewBank={taskReviewBank}
                    queryTaskId={queryTaskId}
                />
                {isLoading && (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                    >
                        <Spinner
                            variant="primary"
                            style={{
                                height: "200px",
                                width: "200px",
                                borderWidth: "10px",
                            }}
                        />
                    </div>
                )}
                {!isLoading && Object.keys(taskReviewBank).length && (
                    <div
                        id="task-review-pane-container"
                        className="d-flex px-3 pt-3"
                    >
                        <TextPane
                            taskReviewBank={taskReviewBank}
                            queryTaskId={queryTaskId}
                        />
                        {taskReviewBank[queryTaskId]["bitmap"] && (
                            <ImagePane
                                original={taskReviewBank[queryTaskId].images[0]}
                                bitmap={taskReviewBank[queryTaskId].bitmap}
                            />
                        )}
                        {taskReviewBank[queryTaskId]["metadata"] && (
                            <MetadataPane
                                taskReviewBank={taskReviewBank}
                                queryTaskId={queryTaskId}
                            />
                        )}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default TaskReview;
