import { useContext, useEffect, useState } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import LocationGroup from "./LocationGroup";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";

const Locations = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);

    const taskName = mission.name;
    const taskOptionsPrimary = mission.values;

    const [locations, setLocations] = useState([
        { primary: "", secondary: [] },
    ]);

    useEffect(
        () => {
            if (locations[0].primary)
                setTaskData({ ...taskData, [taskName]: locations });
        },
        // eslint-disable-next-line
        [locations]
    );

    const addLocation = () => {
        setLocations([...locations, { primary: "", secondary: [] }]);
    };

    return (
        <div className="py-4 fieldset fieldset-radio">
            <h6 className="mb-3">{mission.description}</h6>
            {locations.map((location, index) => {
                return (
                    <LocationGroup
                        key={index}
                        taskSetup={taskSetup}
                        locationSlot={index + 1}
                        taskName={taskName}
                        taskOptionsPrimary={taskOptionsPrimary}
                        locations={locations}
                        setLocations={setLocations}
                    />
                );
            })}
            <Button size="sm" className="mt-2" onClick={addLocation}>
                <Plus className="me-1" />
                Add Location
            </Button>
        </div>
    );
};

export default Locations;
