import { useEffect, useState } from "react";

import StatusFilterContainer from "../cases/StatusFilterContainer";
import PaginationHeader from "./PaginationHeader";
import TableContent from "./TableContent";

const TableContainer = ({
    type,
    handleRowClick,
    data,
    visibleColumns,
    hasStatusFilter,
}) => {
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [areAllFiltersChecked, setAreAllFiltersChecked] = useState(true);
    const [isFilterChecked, setIsFilterChecked] = useState({
        "not-started": true,
        "attention-needed": true,
        completed: true,
    });

    useEffect(() => {
        const filteredData = data.filter(item => {
            return Object.keys(isFilterChecked)
                .filter(statusFilter => isFilterChecked[statusFilter] === true)
                .includes(item.status);
        });
        setDataToDisplay(filteredData);
        // eslint-disable-next-line
    }, [isFilterChecked]);

    const [activePaginationButton, setActivePaginationButton] = useState(1);

    return (
        <div>
            <PaginationHeader
                dataToDisplay={dataToDisplay}
                activePaginationButton={activePaginationButton}
                setActivePaginationButton={setActivePaginationButton}
            />
            {hasStatusFilter && (
                <StatusFilterContainer
                    areAllFiltersChecked={areAllFiltersChecked}
                    setAreAllFiltersChecked={setAreAllFiltersChecked}
                    isFilterChecked={isFilterChecked}
                    setIsFilterChecked={setIsFilterChecked}
                    setActivePaginationButton={setActivePaginationButton}
                />
            )}
            <TableContent
                type={type}
                handleRowClick={handleRowClick}
                dataToDisplay={dataToDisplay}
                setDataToDisplay={setDataToDisplay}
                visibleColumns={visibleColumns}
                activePaginationButton={activePaginationButton}
                isFilterChecked={isFilterChecked}
            />
        </div>
    );
};

export default TableContainer;
