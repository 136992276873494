import axios from "./axiosInstance";

export const fetchTaskList = async () => {
    try {
        const token = localStorage.getItem("authToken");
        const res = await axios.get("/task/returnTaskList", {
            headers: { Authorization: `Bearer ${token}` },
        });
        const { taskList } = res.data;
        return taskList;
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
