import { useState } from "react";
import axios from "../../utilities/axiosInstance";
import { Alert, Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PersonFillAdd } from "react-bootstrap-icons";

const SignupBody = () => {
    const [signupData, setSignupData] = useState({
        firstName: "",
        lastName: "",
        type: "null",
        email: "",
        login: "",
        password: "",
        confirmPassword: "",
    });

    /* eslint-disable */

    const [showSignupSpinner, setShowSignupSpinner] = useState(false);

    const [showSignupAlert, setShowSignupAlert] = useState(false);
    const [signupAlertMsg, setSignupAlertMsg] = useState("");

    /* eslint-enable */

    const handleSignupDataChange = e => {
        setSignupData({
            ...signupData,
            [e.target.id]: e.target.value,
        });
    };

    const navigate = useNavigate();

    /* Insert real login logic with backend auth here later */

    const handleSignup = async e => {
        e.preventDefault();
        setShowSignupAlert(false);
        setShowSignupSpinner(true);
        try {
            const res = await axios.post("/auth/signup", signupData);
            if (res.data.ok) navigate("/?signedUp=1");
        } catch (err) {
            setSignupAlertMsg(err.response.data.message);
            setShowSignupAlert(true);
        } finally {
            setShowSignupSpinner(false);
        }
    };

    return (
        <main className="d-flex flex-column justify-content-center align-items-center">
            <h2 className="mb-5">Welcome to the MyDerma Annotation Tool</h2>
            <Form id="login-form" onSubmit={handleSignup}>
                <div className="mb-3">
                    Existing user?
                    <Link to="/" className="ms-1">
                        Log in
                    </Link>
                </div>
                <div className="d-flex mb-2">
                    <FloatingLabel label="First Name" className="me-1">
                        <Form.Control
                            required
                            id="firstName"
                            value={signupData.firstName}
                            onChange={handleSignupDataChange}
                            placeholder="First Name"
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Last Name" className="ms-1">
                        <Form.Control
                            required
                            id="lastName"
                            value={signupData.lastName}
                            onChange={handleSignupDataChange}
                            placeholder="Last Name"
                        />
                    </FloatingLabel>
                </div>
                <FloatingLabel
                    label="Are you a physician or medical student?"
                    className="mb-2"
                >
                    <Form.Select
                        required
                        id="type"
                        onChange={handleSignupDataChange}
                    >
                        <option value="null">No</option>
                        <option value="dermatologist">
                            Yes, a dermatologist
                        </option>
                        <option value="other-md">Yes, other physician</option>
                        <option value="med-student">
                            Yes, a medical student
                        </option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="Email" className="mb-2">
                    <Form.Control
                        type="email"
                        id="email"
                        value={signupData.email}
                        onChange={handleSignupDataChange}
                        placeholder="Email"
                    />
                </FloatingLabel>
                <FloatingLabel label="Username" className="mb-2">
                    <Form.Control
                        required
                        id="login"
                        value={signupData.login}
                        onChange={handleSignupDataChange}
                        placeholder="Username"
                    />
                </FloatingLabel>
                <FloatingLabel label="Password" className="mb-2">
                    <Form.Control
                        required
                        type="password"
                        id="password"
                        value={signupData.password}
                        onChange={handleSignupDataChange}
                        placeholder="Password"
                        className="mb-2"
                    />
                </FloatingLabel>
                <FloatingLabel label="Confirm Password" className="mb-3">
                    <Form.Control
                        required
                        type="password"
                        id="confirmPassword"
                        value={signupData.confirmPassword}
                        onChange={handleSignupDataChange}
                        placeholder="Confirm Password"
                    />
                </FloatingLabel>
                <div
                    className={`d-flex justify-content-${
                        showSignupSpinner || showSignupAlert ? "between" : "end"
                    } align-items-center`}
                >
                    <div>
                        {showSignupSpinner && <Spinner variant="primary" />}
                        <Alert
                            show={showSignupAlert}
                            id="signup-alert"
                            variant="danger"
                            className="px-2 py-1 me-2 mb-0"
                        >
                            {signupAlertMsg}
                        </Alert>
                    </div>
                    <Button type="submit">
                        <PersonFillAdd className="me-2" />
                        Sign Up
                    </Button>
                </div>
            </Form>
        </main>
    );
};

export default SignupBody;
