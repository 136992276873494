import axios from "./axiosInstance";
import { fetchImages } from "./fetchImages";

export const fetchRemainingTaskReviewData = async (
    taskId,
    caseId,
    missionId
) => {
    const token = localStorage.getItem("authToken");

    const getAnamnesis = async () => {
        const res = await axios.get(`/task/returnAnamnesis?case_id=${caseId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        const { anamnesis } = res.data;
        return anamnesis;
    };

    const getImages = async filenames => {
        let images;
        if (filenames) {
            images = await fetchImages(filenames);
        } else {
            const res = await axios.get(
                `/task/returnImageFilename?task_id=${taskId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const { filename } = res.data;
            images = await fetchImages([filename]);
        }
        return images;
    };

    const getOutput = async () => {
        const res = await axios.get(`/task/returnOutput?task_id=${taskId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return res.data;
    };

    const getBitmap = async filename => {
        const res = await axios.post(
            `/task/returnBitmap?task_id=${taskId}`,
            { filename },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return res.data;
    };

    const makeImageFilenamesArray = anamnesis => {
        const imageObjectsInAnamnesis = anamnesis.filter(property =>
            property.labels.name.includes("image_urls")
        );

        let imageUrls = [];
        for (const image of imageObjectsInAnamnesis) {
            if (image.data) {
                imageUrls = imageUrls.concat(image.data);
            }
        }
        return imageUrls.map(image =>
            image.replace("gs://dermadetect-20180424.appspot.com/000RN/", "")
        );
    };

    try {
        const anamnesis = await getAnamnesis();
        if (missionId === 1) {
            const images = await getImages();
            const { filename, validity, annotator_id } = await getOutput();
            const { bitmap } = await getBitmap(filename);
            return { anamnesis, images, bitmap, validity, annotator_id };
        } else {
            const imageFilenames = makeImageFilenamesArray(anamnesis);
            const images = await getImages(imageFilenames);
            const { metadata, validity, annotator_id } = await getOutput();
            return { anamnesis, images, metadata, validity, annotator_id };
        }
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
