import { Form } from "react-bootstrap";
import { useEffect } from "react";
import StatusBadge from "./StatusBadge";

const StatusFilter = ({
    areAllFiltersChecked,
    setAreAllFiltersChecked,
    isFilterChecked,
    setIsFilterChecked,
    setActivePaginationButton,
}) => {
    useEffect(() => {
        setAreAllFiltersChecked(
            Object.values(isFilterChecked).includes(false) ? false : true
        );
    }, [setAreAllFiltersChecked, isFilterChecked]);

    const handleCheckFilter = status => {
        if (status === "all") {
            const obj = JSON.parse(JSON.stringify(isFilterChecked));
            Object.keys(obj).forEach(
                key => (obj[key] = !areAllFiltersChecked ? true : false)
            );
            setIsFilterChecked(obj);
            setAreAllFiltersChecked(!areAllFiltersChecked);
        } else {
            setIsFilterChecked({
                ...isFilterChecked,
                [status]: !isFilterChecked[status],
            });
        }
        setActivePaginationButton(1);
    };

    const statuses = ["all", "not-started", "attention-needed", "completed"];

    return statuses.map(status => {
        return (
            <Form.Group
                className="d-flex align-items-center me-3 switch"
                key={status}
            >
                <StatusBadge status={status} isLabelClickable={true} />

                <Form.Check
                    type="switch"
                    id={`switch-filter-${status}`}
                    checked={
                        status === "all"
                            ? areAllFiltersChecked
                            : isFilterChecked[status]
                    }
                    onChange={() => handleCheckFilter(status)}
                />
            </Form.Group>
        );
    });
};

export default StatusFilter;
