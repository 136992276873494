import { useContext } from "react";
import { WorkspaceContext } from "../../contexts/Workspace";
import Anamnesis from "./Anamnesis";

const TaskDetailsPane = () => {
    const { caseImages, taskSetup } = useContext(WorkspaceContext);

    return (
        <div
            id="workspace-task-details-pane"
            className="d-flex flex-column me-2"
        >
            <div>
                <h4 className="text-decoration-underline">Anamnesis</h4>
                <div id="workspace-task-details-anamnesis" className="me-2">
                    <Anamnesis anamnesis={taskSetup.task.anamnesis} />
                </div>
            </div>
            <div
                id="workspace-task-details-images"
                className="d-flex flex-column pe-3"
            >
                {caseImages.map((base64String, index) => {
                    return (
                        <img
                            src={base64String}
                            className="task-image mt-2"
                            alt="task Details"
                            key={index}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default TaskDetailsPane;
