import { useContext } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const Bool = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);

    const taskName = mission.name;

    return (
        <div className="py-4 fieldset fieldset-bool">
            <h6>{mission.description}</h6>
            <Form.Check inline>
                <Form.Check.Input
                    required={taskSetup.mission.length === 1 ? true : false}
                    name={taskName}
                    type="radio"
                    onChange={e =>
                        setTaskData({ ...taskData, [taskName]: e.target.value })
                    }
                    value={true}
                    id={taskName + "-yes"}
                />
                <Form.Check.Label htmlFor={taskName + "-yes"}>
                    Yes
                </Form.Check.Label>
            </Form.Check>
            <Form.Check inline>
                <Form.Check.Input
                    required={taskSetup.mission.length === 1 ? true : false}
                    name={taskName}
                    type="radio"
                    onChange={e =>
                        setTaskData({ ...taskData, [taskName]: e.target.value })
                    }
                    value={false}
                    id={taskName + "-no"}
                />
                <Form.Check.Label htmlFor={taskName + "-no"}>
                    No
                </Form.Check.Label>
            </Form.Check>
        </div>
    );
};

export default Bool;
