const Footer = () => {
    return (
        <footer className="text-center">
            Copyright ©{" "}
            <a
                href="https://www.dermadetect.com/"
                target="_blank"
                rel="noreferrer"
            >
                www.dermadetect.com
            </a>{" "}
            2023
        </footer>
    );
};

export default Footer;
