const ImageViewer = ({ original, bitmap, zoom, bitmapOpacity }) => {
    return (
        <div id="task-review-img-viewer">
            <div
                id="task-review-img-wrapper"
                style={{ transform: `scale(${zoom / 100})` }}
            >
                <img
                    id="task-review-original-image"
                    src={original}
                    className="task-review-img"
                    alt="Original"
                />
                <img
                    id="task-review-bitmap"
                    src={bitmap}
                    className={`opacity-${bitmapOpacity} task-review-img`}
                    alt="Bitmap mask"
                />
            </div>
        </div>
    );
};

export default ImageViewer;
