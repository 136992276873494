import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import { Spinner } from "react-bootstrap";
import { validateToken } from "../../utilities/validateToken";
import { fetchUser } from "../../utilities/fetchUser";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isCheckingToken, setIsCheckingToken] = useState(true);

    const { setCurrentUser } = useContext(AuthContext);

    const location = useLocation();

    useEffect(() => {
        validateToken().then(result => {
            setIsTokenValid(result);
            if (!result) localStorage.removeItem("authToken");
            setIsCheckingToken(false);
        });

        const restoreUser = async () => {
            const user = await fetchUser();
            if (user) {
                const { id, firstName, role } = user;
                setCurrentUser({ id, firstName, role });
            }
        };
        if (localStorage.getItem("authToken")) {
            restoreUser();
        }
        // eslint-disable-next-line
    }, []);

    /* Use commented out code below to display spinner if token validation takes a noticeably long time visually*/

    if (isCheckingToken) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Spinner
                    variant="primary"
                    style={{
                        height: "200px",
                        width: "200px",
                        borderWidth: "10px",
                    }}
                />
            </div>
        );
    } else {
        return isTokenValid ? (
            children
        ) : (
            <Navigate
                to={`/?redirectOnLogin=${encodeURIComponent(
                    location.pathname + location.search
                )}`}
            />
        );
    }
};

export default PrivateRoute;
