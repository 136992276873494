import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaskReviewContext } from "../../contexts/TaskReview";
import { fetchUserCount } from "../../utilities/fetchUserCount";
import { fetchTaskCount } from "../../utilities/fetchTaskCount";
import { fetchTaskList } from "../../utilities/fetchTaskList";
import TableContainer from "../../components/tables/TableContainer";
import MonitoringStatCard from "./MonitoringStatCard";
import { Spinner } from "react-bootstrap";
import "../../styles/Monitoring.css";

const Monitoring = () => {
    // eslint-disable-next-line

    const [isLoading, setIsLoading] = useState(true);

    const [monitoringStats, setMonitoringStats] = useState({
        totalTasks: {
            name: "Total Tasks",
            value: null,
        },
        completedTasks: {
            name: "Completed Tasks",
            value: null,
        },
        taskCompletionRate: {
            name: "Task Completion Rate",
            value: null,
        },
        totalUsers: {
            name: "Total Users",
            value: null,
        },
    });

    const [taskList, setTaskList] = useState([]);

    const { setTaskReviewBank } = useContext(TaskReviewContext);

    useEffect(() => {
        const populateStats = async () => {
            const taskCount = await fetchTaskCount();
            const userCount = await fetchUserCount();
            setMonitoringStats({
                totalTasks: {
                    ...monitoringStats.totalTasks,
                    value: taskCount.total.toLocaleString(),
                },
                completedTasks: {
                    ...monitoringStats.completedTasks,
                    value: taskCount.completed.toLocaleString(),
                },
                taskCompletionRate: {
                    ...monitoringStats.taskCompletionRate,
                    value: `${(
                        (taskCount.completed / taskCount.total) *
                        100
                    ).toFixed(2)}%`,
                },
                totalUsers: {
                    ...monitoringStats.totalUsers,
                    value: userCount,
                },
            });
        };

        const populateTaskList = async () => {
            const tasks = await fetchTaskList();
            setTaskList(tasks);
            const filtered = tasks.filter(
                task => task.status !== "not-started"
            );
            let obj = {};
            for (const task of filtered) {
                obj[task.task_id] = task;
            }
            setTaskReviewBank(obj);
        };

        try {
            populateStats();
            populateTaskList();
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (taskList.length) {
            setIsLoading(false);
        }
    }, [taskList]);

    const navigate = useNavigate();

    const goToTaskReview = task_id => {
        navigate(`/task-review?task_id=${task_id}`);
    };

    return (
        <div>
            {isLoading && (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "400px" }}
                >
                    <Spinner
                        variant="primary"
                        style={{
                            height: "200px",
                            width: "200px",
                            borderWidth: "10px",
                        }}
                    />
                </div>
            )}
            {!isLoading && (
                <div className="d-flex pe-3">
                    <div
                        id="monitoring-stats"
                        className="w-25 d-flex flex-column mt-4"
                    >
                        <h2 className="text-decoration-underline">
                            Statistics
                        </h2>
                        <div className="d-flex flex-wrap ">
                            {Object.values(monitoringStats).map(stat => (
                                <MonitoringStatCard
                                    key={stat.name}
                                    stat={stat}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="w-75 d-flex flex-column mt-4">
                        <h2 className="text-decoration-underline">Tasks</h2>
                        <TableContainer
                            type="task-list-admin"
                            handleRowClick={goToTaskReview}
                            data={taskList}
                            visibleColumns={{
                                task_id: "Task ID",
                                case_id: "Case ID",
                                mission_id: "Mission ID",
                                mission_name: "Mission Name",
                                status: "Status",
                                updated_at: "Last Updated",
                            }}
                            hasStatusFilter={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Monitoring;
