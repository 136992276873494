import { useContext } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const Number = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);

    const taskName = mission.name;

    return (
        <div className="py-4 fieldset fieldset-number">
            <h6>{mission.description}</h6>
            <Form.Control
                required={taskSetup.mission.length === 1 ? true : false}
                type="number"
                onChange={e =>
                    setTaskData({
                        ...taskData,
                        [taskName]: parseInt(e.target.value),
                    })
                }
                min={mission.values.min}
                max={mission.values.max ? mission.values.max : null}
            />
        </div>
    );
};

export default Number;
