export const validateCheckboxes = (taskSetup, taskData) => {
    if (taskData.location) {
        let emptyCheckboxesPresent = false;
        for (const location of taskData.location) {
            if (!Object.values(location.secondary).length) {
                emptyCheckboxesPresent = true;
                break;
            }
        }
        if (emptyCheckboxesPresent) {
            return false;
        }
    }
    if (taskSetup.mission[0].name === "texture") {
        if (!taskData.texture || (taskData.texture && !taskData.texture.length))
            return false;
    }
    if (taskSetup.mission[0].name === "color") {
        if (!taskData.color || (taskData.color && !taskData.color.length))
            return false;
    }
    return true;
};
