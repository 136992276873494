import Header from "../components/global/Header";
import Monitoring from "./dashboard/Monitoring";
import UserManagement from "./dashboard/UserManagement";
import Footer from "../components/global/Footer";
import { Tabs, Tab } from "react-bootstrap";

const Dashboard = () => {
    document.title = "Dashboard | MyDerma Annotaton Tool";

    return (
        <div id="dashboard" className="page-layout">
            <Header subtitle="Dashboard"></Header>
            <main className="p-3">
                <Tabs variant="pills" defaultActiveKey="monitoring">
                    <Tab eventKey="monitoring" title="Monitoring">
                        <Monitoring />
                    </Tab>
                    <Tab eventKey="user-management" title="User Management">
                        <UserManagement />
                    </Tab>
                </Tabs>
            </main>
            <Footer></Footer>
        </div>
    );
};

export default Dashboard;
