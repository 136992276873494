import { useContext } from "react";
import { WorkspaceContext } from "../../contexts/Workspace";
import { Alert, Button, Spinner } from "react-bootstrap";
import { ArrowCounterclockwise, ArrowRight } from "react-bootstrap-icons";

const TaskNavbar = () => {
    const { taskSetup, isSaving, saveAlertMessage, showSaveAlert } =
        useContext(WorkspaceContext);

    return (
        <div
            id="workspace-task-navbar"
            className="d-flex justify-content-between align-items-center px-3 py-1"
        >
            <div
                id="workspace-task-details"
                className="d-flex align-items-center"
            >
                <h4 className="me-4 mb-0">
                    <span className="fw-bold">{"Task Type: "}</span>
                    {taskSetup.task.missionLabel}
                </h4>
                <h6 className="me-4 mb-0">
                    <span className="fw-bold">{"Task ID: "}</span>
                    {taskSetup.task.taskId}
                </h6>
                <h6 className="me-4 mb-0">
                    <span className="fw-bold">{"Case ID: "}</span>
                    {taskSetup.task.caseId}
                </h6>
                <Button size="sm" onClick={() => window.location.reload()}>
                    <ArrowCounterclockwise className="me-1" />
                    Reset Task
                </Button>
            </div>
            <div className="d-flex align-items-center">
                {showSaveAlert && (
                    <Alert
                        variant="danger"
                        size="sm"
                        className="mb-0 px-2 py-1"
                    >
                        {saveAlertMessage}
                    </Alert>
                )}
                {isSaving && <Spinner variant="primary" />}
                <Button type="submit" size="sm" className="ms-3">
                    <span className="me-2">Save and Move to Next Task</span>
                    <ArrowRight />
                </Button>
            </div>
        </div>
    );
};

export default TaskNavbar;
