import MyDermaLogo from "../../images/myDerma-logo.svg";
import MyDermaWordmark from "../../images/myDerma-wordmark.svg";

const LoginHeader = () => {
    return (
        <header className="d-flex justify-content-between align-items-center px-5">
            <div
                id="myDermaLogoGroup"
                className="d-flex align-items-center ms-4"
            >
                <img src={MyDermaLogo} alt="MyDerma logo" id="myderma-logo" />
                <img
                    src={MyDermaWordmark}
                    alt="MyDerma wordmark"
                    id="myderma-wordmark"
                />
            </div>
            <small>v1.0.0</small>
        </header>
    );
};

export default LoginHeader;
