import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const LocationGroup = ({
    taskSetup,
    locationSlot,
    taskName,
    taskOptionsPrimary,
    locations,
    setLocations,
}) => {
    const [taskOptionsSecondary, setTaskOptionsSecondary] = useState([]);

    const primaryLocationValue = locations[locationSlot - 1].primary;
    const secondaryLocationValues = locations[locationSlot - 1].secondary;

    useEffect(() => {
        if (primaryLocationValue) {
            const targetLocation = taskOptionsPrimary.filter(
                obj => obj.value === primaryLocationValue
            )[0];
            setTaskOptionsSecondary(targetLocation.secondary);
        }
    }, [primaryLocationValue, taskOptionsPrimary]);

    const handleChangeLocationPrimary = e => {
        setLocations(prevState => {
            const updatedState = [...prevState];
            updatedState.splice(locationSlot - 1, 1, {
                primary: e.target.value,
                secondary: [],
            });
            return updatedState;
        });
    };

    const handleChangeLocationSecondary = e => {
        setLocations(prevState => {
            let updatedSecondaryLocationValues;
            if (secondaryLocationValues.includes(e.target.value)) {
                updatedSecondaryLocationValues = secondaryLocationValues.filter(
                    option => option !== e.target.value
                );
            } else {
                updatedSecondaryLocationValues = [
                    ...secondaryLocationValues,
                    e.target.value,
                ];
            }
            const updatedState = [...prevState];
            updatedState.splice(locationSlot - 1, 1, {
                primary: primaryLocationValue,
                secondary: updatedSecondaryLocationValues,
            });
            return updatedState;
        });
    };

    return (
        <div className="mb-4">
            <div>
                <h6 className="text-decoration-underline">
                    {`Main (Primary) Location ${locationSlot}:`}
                </h6>
                {taskOptionsPrimary.map(option => {
                    return (
                        <Form.Check
                            inline
                            key={
                                taskName +
                                locationSlot +
                                "-primary-" +
                                option.value
                            }
                            className="me-4"
                        >
                            <Form.Check.Input
                                required={
                                    taskSetup.mission.length === 1
                                        ? true
                                        : false
                                }
                                name={taskName + locationSlot}
                                type="radio"
                                onChange={handleChangeLocationPrimary}
                                value={option.value}
                                id={
                                    taskName +
                                    locationSlot +
                                    "-primary-" +
                                    option.value
                                }
                            />
                            <Form.Check.Label
                                htmlFor={
                                    taskName +
                                    locationSlot +
                                    "-primary-" +
                                    option.value
                                }
                            >
                                {option.label}
                            </Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </div>
            {primaryLocationValue && (
                <div>
                    <h6 className="text-decoration-underline">
                        {`Precise (Secondary) Location(s) ${locationSlot}:`}
                    </h6>
                    {taskOptionsSecondary.map(option => {
                        return (
                            <Form.Check
                                inline
                                key={
                                    taskName +
                                    "-secondary-" +
                                    locationSlot +
                                    option.value
                                }
                                className="me-4"
                            >
                                <Form.Check.Input
                                    name={
                                        taskName + "-secondary-" + locationSlot
                                    }
                                    type="checkbox"
                                    onChange={handleChangeLocationSecondary}
                                    value={option.value}
                                    id={
                                        taskName +
                                        "-secondary-" +
                                        locationSlot +
                                        "-" +
                                        option.value
                                    }
                                />
                                <Form.Check.Label
                                    htmlFor={
                                        taskName +
                                        "-secondary-" +
                                        locationSlot +
                                        "-" +
                                        option.value
                                    }
                                >
                                    {option.label}
                                </Form.Check.Label>
                            </Form.Check>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default LocationGroup;
