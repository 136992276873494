import { Accordion } from "react-bootstrap";
import StatusBadge from "../cases/StatusBadge";
import Anamnesis from "../workspace/Anamnesis";

const TextPane = ({ taskReviewBank, queryTaskId }) => {
    const task = taskReviewBank[queryTaskId];

    const {
        task_id,
        status,
        case_id,
        mission_name,
        mission_description,
        updated_at,
        validity,
        annotator_id,
    } = task;

    return (
        <div id="task-review-text-pane" className="me-2">
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Task Details</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            <span className="fw-bold">Task ID:</span> {task_id}
                        </p>
                        <p>
                            <span className="fw-bold me-2">Status:</span>
                            <StatusBadge
                                status={status}
                                isLabelClickable={false}
                            />
                        </p>
                        <p>
                            <span className="fw-bold">Case ID:</span> {case_id}
                        </p>
                        <p>
                            <span className="fw-bold">Last annotated at:</span>{" "}
                            {new Date(updated_at).toLocaleString()}
                        </p>
                        {annotator_id && (
                            <p>
                                <span className="fw-bold">Annotator ID:</span>{" "}
                                {annotator_id}
                            </p>
                        )}
                        <p>
                            <span className="fw-bold">Task type:</span>{" "}
                            {mission_name}
                        </p>
                        <p>
                            <span className="fw-bold">
                                Instructions to annotator:
                            </span>{" "}
                            {mission_description}
                        </p>
                        <p>
                            <span className="fw-bold">
                                Annotator's assessment of image validty:
                            </span>{" "}
                            {validity}
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                {task.anamnesis ? (
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Anamnesis</Accordion.Header>
                        <Accordion.Body id="task-review-anamnesis-container">
                            {task.anamnesis && (
                                <Anamnesis anamnesis={task.anamnesis} />
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                ) : null}
            </Accordion>
        </div>
    );
};

export default TextPane;
