import { useContext } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const Validity = () => {
    const { taskData, setTaskData } = useContext(WorkspaceContext);
    const validityOptions = [
        { value: "VALID", label: "Case is valid" },
        { value: "IRRELEVANT", label: "Case is irrelevant to telemedicine" },
        {
            value: "UNCLEAR_IMAGE",
            label: "Case image(s) or anamnesis are unclear",
        },
        {
            value: "NO_DOUBT",
            label: "In doubt / expert advice required",
        },
    ];

    return (
        <div id="fieldset-image-validity-container" className="py-4 fieldset">
            <div id="fieldset-validity">
                <h6>Case Validity:</h6>
                <Form.Select
                    required
                    size="sm"
                    onChange={e =>
                        setTaskData({ ...taskData, validity: e.target.value })
                    }
                >
                    {validityOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Form.Select>
            </div>
        </div>
    );
};

export default Validity;
