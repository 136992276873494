import { useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
const Disease = ({
    taskSetup,
    diagnosisSlot,
    diffDiagnosis,
    setDiffDiagnosis,
    diseasesList,
}) => {
    const commentTextAreaRef = useRef(null);
    const severityLevelSelectRef = useRef(null);

    const currentCommentValue = diffDiagnosis[diagnosisSlot - 1].comment;
    const currentSeverityLevelValue =
        diffDiagnosis[diagnosisSlot - 1].severityLevel;

    useEffect(() => {
        if (commentTextAreaRef.current) {
            commentTextAreaRef.current.style.height = "0px";
            const scrollHeight = commentTextAreaRef.current.scrollHeight;
            commentTextAreaRef.current.style.height = scrollHeight + "px";
        }
    }, [commentTextAreaRef, currentCommentValue]);

    useEffect(() => {
        switch (currentSeverityLevelValue) {
            case "mild":
                severityLevelSelectRef.current.style.backgroundColor =
                    "#00910c";
                severityLevelSelectRef.current.style.color = "#FFFFFF";
                break;
            case "moderate":
                severityLevelSelectRef.current.style.backgroundColor =
                    "#FFE733";
                severityLevelSelectRef.current.style.color = "#000000";
                break;
            case "severe":
                severityLevelSelectRef.current.style.backgroundColor =
                    "#FF8C01";
                severityLevelSelectRef.current.style.color = "#FFFFFF";
                break;
            case "lifeThreatening":
                severityLevelSelectRef.current.style.backgroundColor =
                    "#ED2938";
                severityLevelSelectRef.current.style.color = "#FFFFFF";
                break;
            default:
                break;
        }
    }, [severityLevelSelectRef, currentSeverityLevelValue]);

    const handleChangeDisease = selected => {
        const newDiffDiagnosis = diffDiagnosis.map((disease, index) => {
            if (index === diagnosisSlot - 1) {
                return {
                    ...diffDiagnosis[index],
                    code: selected.length ? selected[0].code : "",
                    disease_id: selected.length ? selected[0].id : 0,
                    text: selected.length ? selected[0].name : "",
                };
            } else {
                return disease;
            }
        });
        setDiffDiagnosis(newDiffDiagnosis);
    };

    const handleChangeCommentOrSeverity = (e, field) => {
        // eslint-disable-next-line
        const newDiffDiagnosis = diffDiagnosis.map((disease, index) => {
            if (index === diagnosisSlot - 1) {
                if (field === "comment") {
                    return {
                        ...diffDiagnosis[index],
                        comment: e.target.value,
                    };
                }
                if (field === "severityLevel") {
                    return {
                        ...diffDiagnosis[index],
                        severityLevel: e.target.value,
                    };
                }
            } else {
                return disease;
            }
        });
        setDiffDiagnosis(newDiffDiagnosis);
    };

    return (
        <div className="fieldset-disease d-flex flex-column justify-content-start">
            <h6 className="text-decoration-underline">
                Diagnosis {diagnosisSlot}:
            </h6>
            <div className="d-flex">
                <div className="disease-list-container">
                    <Typeahead
                        inputProps={{
                            required:
                                taskSetup.mission.length === 1 ? true : false,
                        }}
                        size="sm"
                        options={diseasesList}
                        labelKey="name"
                        maxResults={500}
                        clearButton
                        placeholder="Select disease from list or type to filter"
                        onChange={handleChangeDisease}
                        id={`disease-list-${diagnosisSlot}`}
                        className="disease-list"
                    />
                </div>
                <div className="diagnosis-comment-container">
                    <Form.Control
                        as="textarea"
                        ref={commentTextAreaRef}
                        size="sm"
                        rows={1}
                        value={diffDiagnosis[diagnosisSlot - 1].comment}
                        onChange={e =>
                            handleChangeCommentOrSeverity(e, "comment")
                        }
                        placeholder="Comments"
                        className="diagnosis-comment"
                    />
                </div>
                <div className="diagnosis-severity-container">
                    <Form.Select
                        required={taskSetup.mission.length === 1 ? true : false}
                        ref={severityLevelSelectRef}
                        size="sm"
                        value={diffDiagnosis[diagnosisSlot - 1].severityLevel}
                        onChange={e =>
                            handleChangeCommentOrSeverity(e, "severityLevel")
                        }
                    >
                        <option value="" disabled>
                            Severity Level
                        </option>
                        <option value="mild" className="severity-level-mild">
                            Mild
                        </option>
                        <option
                            value="moderate"
                            className="severity-level-moderate"
                        >
                            Moderate
                        </option>
                        <option
                            value="severe"
                            className="severity-level-severe"
                        >
                            Severe
                        </option>
                        <option
                            value="lifeThreatening"
                            className="severity-level-life-threatening"
                        >
                            Life Threatening
                        </option>
                    </Form.Select>
                </div>
            </div>
        </div>
    );
};

export default Disease;
