import { Pagination } from "react-bootstrap";

const PaginationHeader = ({
    dataToDisplay,
    activePaginationButton,
    setActivePaginationButton,
}) => {
    const paginationButtons = [];
    for (let i = 1; i <= Math.floor(dataToDisplay.length / 10) + 1; i++) {
        paginationButtons.push(
            <Pagination.Item
                key={`pagination-button-${i}`}
                active={i === activePaginationButton}
                onClick={() => setActivePaginationButton(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return (
        <div
            className={`d-flex justify-content-${
                dataToDisplay.length > 10 ? "between" : "end"
            } align-items-center`}
        >
            {dataToDisplay.length > 10 && (
                <Pagination className="d-flex flex-wrap w-75 mt-3">
                    {paginationButtons}
                </Pagination>
            )}
            <div
                className={`d-flex flex-column align-items-end ${
                    dataToDisplay.length > 10 ? "" : "my-2"
                }`}
            >
                <span>Total results: {dataToDisplay.length} most recent</span>
                {dataToDisplay.length ? (
                    <span>
                        Showing results {activePaginationButton * 10 - 9} to{" "}
                        {activePaginationButton ===
                        Math.floor(dataToDisplay.length / 10) + 1
                            ? dataToDisplay.length
                            : activePaginationButton * 10}
                    </span>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default PaginationHeader;
