import StatusBadge from "../cases/StatusBadge";

const RowTemplates = ({ type, handleRowClick, item }) => {
    return (
        <tr
            key={item.case_id}
            onClick={() => {
                if (item.status !== "not-started") {
                    handleRowClick(item.task_id);
                }
            }}
        >
            <td className="fw-bold">{item.task_id}</td>
            <td>{item.case_id}</td>
            <td>{item.mission_id}</td>
            <td>{item.mission_name}</td>
            <td>
                <StatusBadge status={item.status} isLabelClickable={false} />
            </td>
            <td>{new Date(item.updated_at).toLocaleString()}</td>
        </tr>
    );
};

export default RowTemplates;
