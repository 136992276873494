import { Button } from "react-bootstrap";
import {
    ArrowCounterclockwise,
    ArrowLeft,
    ArrowRight,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const TaskReviewNavbar = ({ taskReviewBank, queryTaskId }) => {
    const navigate = useNavigate();

    return (
        <div
            id="task-review-navbar"
            className="d-flex justify-content-between px-3 py-1"
        >
            {Object.keys(taskReviewBank).indexOf(queryTaskId) !== 0 && (
                <Button
                    size="sm"
                    onClick={() => {
                        const newIndex =
                            Object.keys(taskReviewBank).indexOf(queryTaskId) -
                            1;
                        const newTask = Object.keys(taskReviewBank)[newIndex];
                        return navigate("/task-review?task_id=" + newTask);
                    }}
                >
                    <ArrowLeft />
                    <span className="ms-2">Previous Task</span>
                </Button>
            )}
            <Button size="sm" onClick={() => navigate("/dashboard")}>
                <ArrowCounterclockwise />
                <span className="ms-2">Return to Task List</span>
            </Button>
            {Object.keys(taskReviewBank).indexOf(queryTaskId) !==
                Object.keys(taskReviewBank).length - 1 && (
                <Button
                    size="sm"
                    onClick={() => {
                        const newIndex =
                            Object.keys(taskReviewBank).indexOf(queryTaskId) +
                            1;
                        const newTask = Object.keys(taskReviewBank)[newIndex];
                        return navigate("/task-review?task_id=" + newTask);
                    }}
                >
                    <span className="me-2">Next Task</span>
                    <ArrowRight />
                </Button>
            )}
        </div>
    );
};

export default TaskReviewNavbar;
