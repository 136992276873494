import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Workspace from "./pages/Workspace";
import PrivateRoute from "./components/global/PrivateRoute";
import AdminRoute from "./components/global/AdminRoute";
import "./styles/App.css";
import "./styles/bootstrapWithCustomColors.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import TaskTypeSelection from "./pages/TaskTypeSelection";
import TaskReview from "./pages/TaskReview";
import AuthContextProvider from "./contexts/Auth";
import WorkspaceContextProvider from "./contexts/Workspace";
import TaskReviewContextProvider from "./contexts/TaskReview";

function App() {
    return (
        <div className="App">
            <AuthContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/signup" element={<Login />} />
                        <Route path="/logout" element={<Login />} />
                        <Route
                            path="/task-type-selection"
                            element={
                                <PrivateRoute>
                                    <TaskTypeSelection />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/workspace"
                            element={
                                <PrivateRoute>
                                    <WorkspaceContextProvider>
                                        <Workspace />
                                    </WorkspaceContextProvider>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <AdminRoute>
                                        <TaskReviewContextProvider>
                                            <Dashboard />
                                        </TaskReviewContextProvider>
                                    </AdminRoute>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/task-review"
                            element={
                                <PrivateRoute>
                                    <AdminRoute>
                                        <TaskReviewContextProvider>
                                            <TaskReview />
                                        </TaskReviewContextProvider>
                                    </AdminRoute>
                                </PrivateRoute>
                            }
                        />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </Routes>
                </BrowserRouter>
            </AuthContextProvider>
        </div>
    );
}

export default App;
