import { Table } from "react-bootstrap";

const MetadataPane = ({ taskReviewBank, queryTaskId }) => {
    return (
        <div id="task-review-metadata-pane" className="d-flex">
            <div
                id="task-review-metadata-pane-image-container"
                className="d-flex flex-column"
            >
                {taskReviewBank[queryTaskId]["images"].map(
                    (base64String, index) => {
                        return (
                            <img
                                src={base64String}
                                className="task-image mb-2"
                                alt="task Details"
                                key={index}
                            />
                        );
                    }
                )}
            </div>
            <div
                id="task-review-metadata-pane-annotation-container"
                className="ps-2"
            >
                <h4 className="text-decoration-underline">
                    Metadata Annotation
                </h4>
                <Table striped>
                    <thead className="bg-secondary">
                        <tr>
                            <th>Key</th>
                            <th className="text-end">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(
                            taskReviewBank[queryTaskId]["metadata"]
                        ).map(property => {
                            return (
                                <tr key={property[0]}>
                                    <td className="fw-bold">
                                        {property[0].toUpperCase()}
                                    </td>
                                    <td className="text-end">
                                        {Array.isArray(property[1]) ? (
                                            <ul>
                                                {property[1].map(option => {
                                                    return (
                                                        <li
                                                            key={option}
                                                            className="text-end"
                                                        >
                                                            {(
                                                                option[0].toUpperCase() +
                                                                option.slice(
                                                                    1,
                                                                    option.length
                                                                )
                                                            ).replace("_", " ")}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : property[1] === "false" ? (
                                            "No"
                                        ) : property[1] === "true" ? (
                                            "Yes"
                                        ) : (
                                            (() => {
                                                if (
                                                    /^([^0-9]*)$/.test(
                                                        property[1]
                                                    )
                                                ) {
                                                    const capitalized =
                                                        property[1][0].toUpperCase() +
                                                        property[1].slice(
                                                            1,
                                                            property[1].length
                                                        );
                                                    return capitalized.replaceAll(
                                                        "_",
                                                        " "
                                                    );
                                                } else {
                                                    return property[1];
                                                }
                                            })()
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default MetadataPane;
