import { useEffect, useState } from "react";
import { fetchCurrentTokenDuration } from "../../utilities/fetchCurrentTokenDuration";
import { submitNewTokenDuration } from "../../utilities/submitNewTokenDuration";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import "../../styles/UserManagement.css";

const UserManagement = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [currentTokenDuration, setCurrentTokenDuration] = useState("");
    const [newTokenDuration, setNewTokenDuration] = useState({});

    useEffect(() => {
        const getCurrentTokenDuration = async () => {
            let res = await fetchCurrentTokenDuration();
            let unit = res.slice(-1, res.length);
            switch (unit) {
                case "s":
                    unit = "seconds";
                    break;
                case "m":
                    unit = "minutes";
                    break;
                case "h":
                    unit = "hours";
                    break;
                case "d":
                    unit = "days";
                    break;
                case "w":
                    unit = "weeks";
                    break;
                case "y":
                    unit = "years";
                    break;
                default:
                    unit = "unknown unit";
            }
            const formatted = res.slice(0, -1) + " " + unit;
            setCurrentTokenDuration(formatted);
            setIsLoading(false);
        };
        try {
            getCurrentTokenDuration();
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }, []);

    const handleChangeNewTokenDuration = e => {
        if (e.target.type === "number")
            setNewTokenDuration({ ...newTokenDuration, value: e.target.value });
        if (e.target.type === "radio")
            setNewTokenDuration({ ...newTokenDuration, unit: e.target.value });
    };

    const handleSubmitNewTokenDuration = async e => {
        e.preventDefault();
        setIsSubmitting(true);
        setShowAlert(false);
        try {
            const res = await submitNewTokenDuration(
                newTokenDuration.value + newTokenDuration.unit
            );
            if (res.data.ok) {
                setAlertVariant("success");
                setAlertMessage("New token duration saved successfully");
                setShowAlert(true);
            } else {
                throw new Error("Error saving new token duration");
            }
        } catch (err) {
            console.log(err);
            setAlertVariant("danger");
            setAlertMessage(err.message);
            setShowAlert(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            {isLoading && (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "400px" }}
                >
                    <Spinner
                        variant="primary"
                        style={{
                            height: "200px",
                            width: "200px",
                            borderWidth: "10px",
                        }}
                    />
                </div>
            )}
            {!isLoading && (
                <div id="user-management" className="m-3">
                    <h3 className="text-decoration-underline">
                        Authentication Token
                    </h3>
                    <p className="fs-5">
                        Authentication tokens granted to all users upon sign-in
                        are currently set to expire after:{" "}
                        <span className="fw-bold">{currentTokenDuration}</span>
                    </p>
                    <Form
                        onSubmit={handleSubmitNewTokenDuration}
                        className="d-flex align-items-center"
                    >
                        <p className="fs-5 mb-0 me-3">Change token duration:</p>
                        <Form.Control
                            required
                            type="number"
                            size="sm"
                            min={1}
                            max={1000}
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-seconds"
                            label="Seconds"
                            value="s"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-minutes"
                            label="Minutes"
                            value="m"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-hours"
                            label="Hours"
                            value="h"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-days"
                            label="Days"
                            value="d"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-weeks"
                            label="Weeks"
                            value="w"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Form.Check
                            required
                            type="radio"
                            name="token-duration"
                            id="token-duration-years"
                            label="Years"
                            value="y"
                            className="me-3"
                            onChange={handleChangeNewTokenDuration}
                        />
                        <Button type="submit" size="sm">
                            Save
                        </Button>
                        {isSubmitting && (
                            <Spinner variant="primary" className="ms-3" />
                        )}
                        <Alert
                            show={showAlert}
                            variant={alertVariant}
                            size="sm"
                            className="px-2 py-1 ms-3 mb-0"
                        >
                            {alertMessage}
                        </Alert>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default UserManagement;
