import { useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import RowTemplates from "./RowTemplates";

const TableContent = ({
    type,
    handleRowClick,
    dataToDisplay,
    setDataToDisplay,
    visibleColumns,
    activePaginationButton,
    isFilterChecked,
}) => {
    const [sortAsc, setSortAsc] = useState();
    const [activeSortIndicator, setActiveSortIndicator] = useState("");

    const sortData = sortBy => {
        setActiveSortIndicator(sortBy);
        setSortAsc(!sortAsc);
        let arr;
        switch (sortBy) {
            case "case_id":
            case "mission_name":
                arr = dataToDisplay.sort((a, b) => {
                    if (sortAsc) {
                        return b[sortBy] > a[sortBy] ? 1 : -1;
                    } else {
                        return a[sortBy] > b[sortBy] ? 1 : -1;
                    }
                });
                break;
            case "status":
                const statusOrder = {
                    "not-started": 1,
                    "attention-needed": 2,
                    completed: 3,
                };
                arr = dataToDisplay.sort((a, b) => {
                    if (sortAsc) {
                        return statusOrder[b.status] - statusOrder[a.status];
                    } else {
                        return statusOrder[a.status] - statusOrder[b.status];
                    }
                });
                break;
            case "updated_at":
                arr = dataToDisplay.sort((a, b) => {
                    if (sortAsc) {
                        return Date.parse(b[sortBy]) - Date.parse(a[sortBy]);
                    } else {
                        return Date.parse(a[sortBy]) - Date.parse(b[sortBy]);
                    }
                });
                break;
            default:
                arr = dataToDisplay.sort((a, b) => {
                    if (sortAsc) {
                        return b[sortBy] - a[sortBy];
                    } else {
                        return a[sortBy] - b[sortBy];
                    }
                });
        }
        setDataToDisplay(arr);
    };

    return (
        <div>
            {(() => {
                if (
                    !dataToDisplay.length &&
                    !Object.values(isFilterChecked).includes(true)
                ) {
                    return (
                        <div className="d-flex justify-content-center">
                            <h4 className="mt-5">
                                Please select at least one status filter to show
                                results
                            </h4>
                        </div>
                    );
                } else if (
                    !dataToDisplay.length &&
                    Object.values(isFilterChecked).includes(true)
                ) {
                    return (
                        <div className="d-flex justify-content-center">
                            <h4 className="mt-5">
                                No results match any of the status filter(s)
                                chosen
                            </h4>
                        </div>
                    );
                } else {
                    return (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {Object.keys(visibleColumns).map(column => {
                                        return (
                                            <th
                                                key={column}
                                                onClick={() => sortData(column)}
                                            >
                                                {visibleColumns[column]}
                                                {activeSortIndicator ===
                                                column ? (
                                                    sortAsc ? (
                                                        <CaretUpFill className="ms-1" />
                                                    ) : (
                                                        <CaretDownFill className="ms-1" />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {dataToDisplay
                                    .slice(
                                        activePaginationButton * 10 - 10,
                                        activePaginationButton ===
                                            Math.floor(
                                                dataToDisplay.length / 10 + 1
                                            )
                                            ? dataToDisplay.length
                                            : activePaginationButton * 10
                                    )
                                    .map(item => (
                                        <RowTemplates
                                            type={type}
                                            handleRowClick={handleRowClick}
                                            item={item}
                                            key={item.task_id}
                                        />
                                    ))}
                            </tbody>
                        </Table>
                    );
                }
            })()}
        </div>
    );
};

export default TableContent;
