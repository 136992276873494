import { createContext, useState } from "react";

export const TaskReviewContext = createContext();

const TaskReviewContextProvider = ({ children }) => {
    const [taskReviewBank, setTaskReviewBank] = useState({});

    return (
        <TaskReviewContext.Provider
            value={{
                taskReviewBank,
                setTaskReviewBank,
            }}
        >
            {children}
        </TaskReviewContext.Provider>
    );
};

export default TaskReviewContextProvider;
