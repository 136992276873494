import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { fetchUserRole } from "../../utilities/fetchUserRole";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ children }) => {
    const [userRole, setUserRole] = useState(false);
    const [isCheckingRole, setIsCheckingRole] = useState(true);

    useEffect(() => {
        fetchUserRole().then(user => {
            const { role } = user;
            setUserRole(role);
            setIsCheckingRole(false);
        });
        // eslint-disable-next-line
    }, []);

    /* Use commented out code below to display spinner if token validation takes a noticeably long time visually*/

    if (isCheckingRole) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Spinner
                    variant="primary"
                    style={{
                        height: "200px",
                        width: "200px",
                        borderWidth: "10px",
                    }}
                />
            </div>
        );
    } else {
        return userRole === 3 ? (
            children
        ) : (
            <Navigate to="/task-type-selection" />
        );
    }
};

export default AdminRoute;
