import axios from "./axiosInstance";

export const fetchMissions = async () => {
    try {
        const res = await axios.get("/mission/returnAvailableMissions", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        });
        if (!res.data.missions.length) return false;
        return res.data.missions;
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
