import axios from "./axiosInstance";

export const submitNewTokenDuration = async duration => {
    try {
        const token = localStorage.getItem("authToken");
        return await axios.put(
            "/auth/setTokenDuration",
            { duration },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
