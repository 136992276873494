import { Button, Form } from "react-bootstrap";
import { Square, SquareFill, ZoomIn, ZoomOut } from "react-bootstrap-icons";

const ImageControls = ({
    zoom,
    setZoom,
    handleZoomChange,
    bitmapOpacity,
    handleOpacityChange,
    toggleShowImage,
}) => {
    return (
        <div
            id="task-review-img-control-container"
            className="d-flex flex-column ms-2"
        >
            <div className="mb-5">
                <Form.Group className="d-flex flex-column">
                    <Form.Label>
                        Zoom (%)
                        <Button
                            size="sm"
                            className="ms-2"
                            onClick={() => setZoom(100)}
                        >
                            Reset
                        </Button>
                    </Form.Label>
                    <div className="d-flex align-items-center">
                        <ZoomOut />
                        <Form.Range
                            className="task-review-img-control-range px-2"
                            value={zoom}
                            min={50}
                            max={500}
                            onChange={handleZoomChange}
                        />
                        <ZoomIn />
                        <Form.Control
                            type="number"
                            min={50}
                            max={500}
                            className="task-review-img-control-input ms-2 p-1"
                            value={zoom}
                            onChange={handleZoomChange}
                        />
                    </div>
                </Form.Group>
            </div>
            <div>
                <Form.Group className="d-flex switch">
                    <Form.Switch
                        id="switch-show-bitmap"
                        onChange={() => toggleShowImage("bitmap")}
                        checked={bitmapOpacity > 0 ? true : false}
                    />
                    <Form.Label htmlFor="switch-show-bitmap">
                        Show Annotation Bitmap (Mask)
                    </Form.Label>
                </Form.Group>
                <Form.Group className="d-flex flex-column">
                    <Form.Label>Bitmap Opacity (%)</Form.Label>
                    <div className="d-flex align-items-center">
                        <Square />
                        <Form.Range
                            className="img-control-range mx-2"
                            value={bitmapOpacity}
                            onChange={e => handleOpacityChange(e, "bitmap")}
                        />
                        <SquareFill />
                        <Form.Control
                            type="number"
                            min={0}
                            max={100}
                            className="task-review-img-control-input ms-2 p-1"
                            value={bitmapOpacity}
                            onChange={e => handleOpacityChange(e, "bitmap")}
                        />
                    </div>
                </Form.Group>
            </div>
        </div>
    );
};

export default ImageControls;
