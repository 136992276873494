import { useContext, useEffect, useMemo, useState } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Button } from "react-bootstrap";
import Disease from "./Disease";
import { Plus } from "react-bootstrap-icons";

const DifferentialDiagnosis = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);

    const taskName = mission.name;

    const diseasesList = useMemo(() => {
        return mission.values.diseasesList.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (b.name < a.name) {
                return 1;
            } else {
                return 0;
            }
        });
    }, [mission.values.diseasesList]);

    const [diffDiagnosis, setDiffDiagnosis] = useState([
        {
            code: "",
            comment: "",
            disease_id: 0,
            severityLevel: "",
            text: "",
        },
    ]);

    useEffect(
        () => {
            if (diffDiagnosis[0].disease_id)
                setTaskData({ ...taskData, [taskName]: diffDiagnosis });
        },
        // eslint-disable-next-line
        [diffDiagnosis]
    );

    const addDiagnosis = () => {
        setDiffDiagnosis([
            ...diffDiagnosis,
            {
                code: "",
                comment: "",
                disease_id: 0,
                severityLevel: "",
                text: "",
            },
        ]);
    };

    return (
        <div id="fieldset-differential-diagnosis" className="py-2 fieldset">
            <h6>{mission.description}</h6>
            {diffDiagnosis.map(disease => (
                <Disease
                    key={diffDiagnosis.indexOf(disease) + 1}
                    taskSetup={taskSetup}
                    diagnosisSlot={diffDiagnosis.indexOf(disease) + 1}
                    diffDiagnosis={diffDiagnosis}
                    setDiffDiagnosis={setDiffDiagnosis}
                    diseasesList={diseasesList}
                />
            ))}
            <Button size="sm" className="mt-2" onClick={addDiagnosis}>
                <Plus className="me-1" />
                Add Diagnosis
            </Button>
        </div>
    );
};

export default DifferentialDiagnosis;
