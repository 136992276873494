import StatusFilter from "./StatusFilter";

const StatusFilterContainer = ({
    areAllFiltersChecked,
    setAreAllFiltersChecked,
    isFilterChecked,
    setIsFilterChecked,
    setActivePaginationButton,
}) => {
    return (
        <div className="d-flex align-items-center mb-3">
            <span className="me-3">Filter by status:</span>
            <StatusFilter
                areAllFiltersChecked={areAllFiltersChecked}
                setAreAllFiltersChecked={setAreAllFiltersChecked}
                isFilterChecked={isFilterChecked}
                setIsFilterChecked={setIsFilterChecked}
                setActivePaginationButton={setActivePaginationButton}
            />
        </div>
    );
};

export default StatusFilterContainer;
