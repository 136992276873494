import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../components/login/LoginHeader";
import LoginBody from "../components/login/LoginBody";
import SignupBody from "../components/login/SignupBody";
import Footer from "../components/global/Footer";
import { fetchUserRole } from "../utilities/fetchUserRole";
import "../styles/Login.css";

const Login = () => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);

    useEffect(() => {
        setIsLoggingOut(
            document.location.pathname === "/logout" ? true : false
        );
        setIsSigningUp(document.location.pathname === "/signup" ? true : false);
        document.title = `${
            isSigningUp ? "Signup" : "Login"
        } | MyDerma Annotaton Tool`;
        // eslint-disable-next-line
    }, [isSigningUp, document.location.pathname]);

    const navigate = useNavigate();

    useEffect(() => {
        if (document.location.pathname !== "/logout") {
            const redirectByRole = async () => {
                const user = await fetchUserRole();
                const { role } = user;
                if (role) {
                    if (role === 3) {
                        navigate("/dashboard");
                    } else {
                        navigate("/task-type-selection");
                    }
                } else {
                    navigate("/task-type-selection");
                }
            };
            if (localStorage.getItem("authToken")) {
                redirectByRole();
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div id="login" className="page-layout">
            <LoginHeader />
            {!isSigningUp && (
                <LoginBody
                    isLoggingOut={isLoggingOut}
                    setIsLoggingOut={setIsLoggingOut}
                />
            )}
            {isSigningUp && <SignupBody />}
            <Footer />
        </div>
    );
};

export default Login;
