import { useContext } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const Radio = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);
    const taskName = mission.name;
    const taskOptions = mission.values;

    return (
        <div className="py-4 fieldset fieldset-radio">
            <h6>{mission.description}</h6>
            <div>
                {taskOptions.map(option => {
                    return (
                        <Form.Check
                            inline
                            key={taskName + "-" + option.value}
                            className="me-4"
                        >
                            <Form.Check.Input
                                required={
                                    taskSetup.mission.length === 1
                                        ? true
                                        : false
                                }
                                name={taskName}
                                type="radio"
                                onChange={e =>
                                    setTaskData({
                                        ...taskData,
                                        [taskName]: e.target.value,
                                    })
                                }
                                value={option.value}
                                id={taskName + "-" + option.value}
                            />
                            <Form.Check.Label
                                htmlFor={taskName + "-" + option.value}
                            >
                                {option.label}
                            </Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </div>
        </div>
    );
};

export default Radio;
