import { useState } from "react";
import ImageControls from "./ImageControls";
import ImageViewer from "./ImageViewer";

const ImagePane = ({ original, bitmap }) => {
    const [bitmapOpacity, setBitmapOpacity] = useState(50);

    const [showBitmap, setShowBitmap] = useState(true);

    const [zoom, setZoom] = useState(100);

    const handleZoomChange = e => {
        setZoom(e.target.value);
    };

    const handleOpacityChange = e => {
        setBitmapOpacity(e.target.value);
        if (e.target.value !== "0") {
            setShowBitmap(true);
        } else {
            setShowBitmap(false);
        }
    };

    const toggleShowImage = () => {
        if (showBitmap) {
            setBitmapOpacity(0);
            setShowBitmap(false);
        } else {
            setBitmapOpacity(50);
            setShowBitmap(true);
        }
    };

    return (
        <div
            id="task-review-image-pane"
            className="d-flex justify-content-between ms-2"
        >
            <ImageViewer
                original={original}
                bitmap={bitmap}
                zoom={zoom}
                bitmapOpacity={bitmapOpacity}
            />
            <ImageControls
                zoom={zoom}
                setZoom={setZoom}
                handleZoomChange={handleZoomChange}
                bitmapOpacity={bitmapOpacity}
                handleOpacityChange={handleOpacityChange}
                toggleShowImage={toggleShowImage}
            />
        </div>
    );
};

export default ImagePane;
