import { Badge } from "react-bootstrap";

const StatusBadge = ({ status, isLabelClickable }) => {
    return (
        <Badge
            pill
            bg={
                (status === "all" && "primary") ||
                (status === "not-started" && "danger") ||
                (status === "attention-needed" && "warning") ||
                (status === "completed" && "success")
            }
            className="badge px-3 pb-1 me-2"
        >
            <label
                htmlFor={isLabelClickable ? `switch-filter-${status}` : ""}
                className={
                    status === "attention-needed" ? "pill-attention-needed" : ""
                }
            >
                {status === "all" && "All"}
                {status === "not-started" && "Not Started"}
                {status === "attention-needed" && "Attention Needed"}
                {status === "completed" && "Completed"}
            </label>
        </Badge>
    );
};

export default StatusBadge;
