import { Card } from "react-bootstrap";
import "../../styles/Monitoring.css";

const MonitoringStatCard = ({ stat }) => {
    return (
        <Card className="monitoring-stat-card">
            <Card.Body>
                <Card.Title>{stat.name}</Card.Title>
                <h2 className="my-0 text-wrap">{stat.value}</h2>
            </Card.Body>
        </Card>
    );
};

export default MonitoringStatCard;
