import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import MyDermaLogo from "../../images/myDerma-logo.svg";
import {
    ArrowReturnRight,
    BoxArrowInLeft,
    CardList,
    Speedometer2,
} from "react-bootstrap-icons";
import "../../styles/Header.css";

const Header = ({ subtitle }) => {
    const { currentUser } = useContext(AuthContext);

    return (
        <header
            id="header"
            className="d-flex justify-content-between align-items-center px-4"
        >
            <div className="d-flex align-items-center">
                <img
                    src={MyDermaLogo}
                    alt="MyDerma logo"
                    id="myderma-logo"
                    className="px-3 my-3"
                />
                <div>
                    <h4 className="fw-bold">MyDerma Annotation Tool</h4>
                    <h5>
                        <ArrowReturnRight className="me-2" />
                        {subtitle}
                    </h5>
                </div>
            </div>
            <div className="d-flex align-items-center">
                {/* Later, dynamically populate name from auth token set after login. Should be pulled from context. */}
                <span>{`Welcome, ${currentUser.firstName}`}</span>
                {document.location.pathname !== "/task-type-selection" ? (
                    <NavLink to="/task-type-selection">
                        <Button className="ms-3">
                            <CardList className="me-2" />
                            Task Type Selection
                        </Button>
                    </NavLink>
                ) : (
                    ""
                )}
                {currentUser.role === 3 &&
                document.location.pathname !== "/dashboard" ? (
                    <NavLink to="/dashboard">
                        <Button className="ms-3">
                            <Speedometer2 className="me-2" />
                            Dashboard
                        </Button>
                    </NavLink>
                ) : (
                    ""
                )}
                {/* Add handleLogout onClick later */}
                <NavLink to="/logout">
                    <Button className="ms-3">
                        <BoxArrowInLeft className="me-2" />
                        Logout
                    </Button>
                </NavLink>
            </div>
        </header>
    );
};

export default Header;
