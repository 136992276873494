import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import { Button, Modal, Spinner } from "react-bootstrap";
import { BoxArrowUpRight, ExclamationCircle } from "react-bootstrap-icons";
import { fetchMissions } from "../utilities/fetchMissions";
import "../styles/TaskTypeSelection.css";

const TaskTypeSelection = () => {
    document.title = "Select Task Type | MyDerma Annotaton Tool";
    const navigate = useNavigate();

    const [taskTypes, setTaskTypes] = useState([]);
    const [showImageAnnotationModal, setShowImageAnnotationModal] =
        useState(false);
    const [emptyList, setEmptyList] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getTaskTypes = async () => {
            try {
                const missions = await fetchMissions();
                if (!missions)
                    throw new Error(
                        "No missions with outstanding tasks returned"
                    );
                missions.sort((a, b) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                });
                setTaskTypes(missions);
            } catch (err) {
                console.log(err);
                setEmptyList(true);
            } finally {
                setIsLoading(false);
            }
        };
        getTaskTypes();
    }, []);

    return (
        <div id="task-type-selection" className="page-layout">
            <Header subtitle="Task Type Selection"></Header>
            <main className="p-3">
                <Modal
                    show={showImageAnnotationModal}
                    onHide={() => setShowImageAnnotationModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Skin Pixel (Image) Annotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-0">
                            The image annotation tool will open in a new window.
                            You will be required to log in again using the same
                            credentials use used on this site, and then will be
                            presented with the next available image annotation
                            task.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <a
                            href="https://annotation-tool.myderma.app/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                variant="primary"
                                size="lg"
                                className="d-flex align-items-center"
                                onClick={() =>
                                    setShowImageAnnotationModal(false)
                                }
                            >
                                <BoxArrowUpRight className="mb-1" />
                                <span className="ms-2">Launch</span>
                            </Button>
                        </a>
                    </Modal.Footer>
                </Modal>
                <div className="p-3">
                    <h2>Select a task type below to begin annotating</h2>
                </div>
                <div
                    id="task-type-selection-body"
                    className="d-flex justify-content-center ms-3"
                >
                    {isLoading && (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "400px" }}
                        >
                            <Spinner
                                variant="primary"
                                style={{
                                    height: "200px",
                                    width: "200px",
                                    borderWidth: "10px",
                                }}
                            />
                        </div>
                    )}
                    {!isLoading && (
                        <div>
                            {!emptyList &&
                                taskTypes.map(type => (
                                    <Button
                                        variant="primary"
                                        key={type.id}
                                        size="lg"
                                        onClick={() => {
                                            if (type.id === 1) {
                                                setShowImageAnnotationModal(
                                                    true
                                                );
                                            } else {
                                                navigate(
                                                    `/workspace?mission_id=${type.id}`
                                                );
                                            }
                                        }}
                                        className="m-3"
                                    >
                                        {type.label}
                                    </Button>
                                ))}
                            {emptyList && (
                                <div className="d-flex align-items-center">
                                    <ExclamationCircle />
                                    <h6 className="ms-2 mb-0">
                                        No metadata annotation tasks are
                                        currently available
                                    </h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer></Footer>
        </div>
    );
};

export default TaskTypeSelection;
