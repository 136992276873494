import { createContext, useState } from "react";

export const WorkspaceContext = createContext();

const WorkspaceContextProvider = ({ children }) => {
    const [taskSetup, setTaskSetup] = useState({
        task: {},
        mission: {},
    });

    const [taskData, setTaskData] = useState({
        validity: "VALID",
    });

    const [caseImages, setCaseImages] = useState([]);

    const [isSaving, setIsSaving] = useState(false);

    const [saveAlertMessage, setSaveAlertMessage] = useState("");
    const [showSaveAlert, setShowSaveAlert] = useState(false);

    return (
        <WorkspaceContext.Provider
            value={{
                taskSetup,
                setTaskSetup,
                taskData,
                setTaskData,
                caseImages,
                setCaseImages,
                isSaving,
                setIsSaving,
                saveAlertMessage,
                setSaveAlertMessage,
                showSaveAlert,
                setShowSaveAlert,
            }}
        >
            {children}
        </WorkspaceContext.Provider>
    );
};

export default WorkspaceContextProvider;
