import { useContext } from "react";
import { WorkspaceContext } from "../../../contexts/Workspace";
import { Form } from "react-bootstrap";

const SkinType = ({ mission }) => {
    const { taskSetup, taskData, setTaskData } = useContext(WorkspaceContext);
    const taskName = mission.name;
    const skinTypeOptions = mission.values.slice(0, -1);
    const unsureOption = mission.values[mission.values.length - 1];

    return (
        <div id="fieldset-skin-type" className="py-4 fieldset">
            <h6>{mission.description}</h6>
            <div className="d-flex align-items-center">
                {skinTypeOptions.map(option => {
                    return (
                        <Form.Check inline key={option.value} className="me-4">
                            <Form.Group className="d-flex flex-column align-items-center">
                                <Form.Check.Input
                                    required={
                                        taskSetup.mission.length === 1
                                            ? true
                                            : false
                                    }
                                    name={taskName}
                                    type="radio"
                                    onChange={e =>
                                        setTaskData({
                                            ...taskData,
                                            [taskName]: e.target.value,
                                        })
                                    }
                                    value={option.value}
                                    id={`skin-${option.value}`}
                                />
                                <Form.Check.Label
                                    htmlFor={`skin-${option.value}`}
                                    className="d-flex flex-column align-items-center"
                                >
                                    {option.label}
                                    <div
                                        style={{
                                            height: "25px",
                                            width: "25px",
                                            backgroundColor: option.hex,
                                        }}
                                    ></div>
                                </Form.Check.Label>
                            </Form.Group>
                        </Form.Check>
                    );
                })}
                <Form.Check inline className="ms-4">
                    <Form.Check.Input
                        required={taskSetup.mission.length === 1 ? true : false}
                        name={taskName}
                        type="radio"
                        onChange={e =>
                            setTaskData({
                                ...taskData,
                                [taskName]: e.target.value,
                            })
                        }
                        value={unsureOption.value}
                        id={`skin-${unsureOption.value}`}
                    />
                    <Form.Check.Label htmlFor="skin-typeUnsure">
                        {unsureOption.label}
                    </Form.Check.Label>
                </Form.Check>
            </div>
        </div>
    );
};

export default SkinType;
