import axios from "./axiosInstance";

export const submitTask = async taskSubmission => {
    try {
        const res = await axios.post("/task/saveTask", taskSubmission, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        });
        if (res.data.ok) return true;
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
