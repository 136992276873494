import axios from "./axiosInstance";

export const fetchTask = async (fetchBy, id) => {
    try {
        let queryParamString = "";
        if (fetchBy === "task") {
            queryParamString = `task_id=${id}`;
        }
        if (fetchBy === "mission") {
            queryParamString = `mission_id=${id}`;
        }
        const res = await axios.get(`/task/returnTask?${queryParamString}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        });
        if (!res.data.task) return false;
        return { task: res.data.task, mission: res.data.mission };
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
