import axios from "./axiosInstance";

export const fetchUserRole = async () => {
    try {
        const token = localStorage.getItem("authToken");
        const res = await axios.get("/auth/returnRole", {
            headers: { Authorization: `Bearer ${token}` },
        });
        return res.data.user;
    } catch (err) {
        console.log(err.response ? err.response.data.message : err);
        return false;
    }
};
